"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createNewConflictStudy = exports.CREATE_CONFLICT_STUDY = exports.checkGrooming2ConflictsMutation = exports.pushLinkTsIdToGroomingMutation = exports.rollbackStudyMutation = exports.retryLinkingStudyMutation = exports.commitStudyLinkMutation = exports.linkStudyMutation = exports.retryStudyMutation = exports.cancelStudyMutation = exports.recoverStudyMutation = exports.completeStudyMutation = exports.archiveStudyMutation = exports.deleteStudyMutation = exports.pushStudyToCasesCostMutation = exports.useSubscriptionStudyUpdated = exports.useSubscriptionStudiesUpdated = exports.genStudyArtifactsUrlMutation = exports.matchStudyMutation = exports.rematchStudyTransactionsMutation = exports.finishStudyMutation = exports.commitStudy = exports.updateStudy = exports.CheckFileHeaders = exports.importRawTransactionMutation = exports.IMPORT_RAW_TRANSACTION = exports.useStudiesData = exports.useStudyData = exports.StudyData = exports.STUDY_QUERY = exports.StudiesData = exports.STUDIES_QUERY = exports.StudyFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const queuedTransaction_1 = require("./queuedTransaction");
const user_1 = require("./user");
exports.StudyFragment = (0, client_1.gql) `
  fragment StudyFragment on Study {
    id
    facilityId
    facilityName
    version
    status
    recordCount
    bamfMatchedCount
    minDatePurchase
    maxDatePurchase
    totalUniqueTsId
    linkedTsId
    committedStudyLink
    conflicts
    fileType
    createdAt
    dueDate
  }
`;
exports.STUDIES_QUERY = (0, client_1.gql) `
  query studies(
    $userId: String
    $first: Int
    $skip: Int
    $importedFrom: Date
    $importedTo: Date
    $statusIn: [StudyStatusEnum!]
    $customerId: String
    $fileType: [StudyFileTypeEnum!]
  ) {
    studies(
      userId: $userId
      first: $first
      skip: $skip
      importedFrom: $importedFrom
      importedTo: $importedTo
      statusIn: $statusIn
      customerId: $customerId
      fileType: $fileType
    ) {
      ...StudyFragment
      user {
        ...UserFragment
      }
    }
  }
  ${exports.StudyFragment}
  ${user_1.UserFragment}
`;
exports.StudiesData = (0, utils_1.buildQuery)({
    query: exports.STUDIES_QUERY,
});
exports.STUDY_QUERY = (0, client_1.gql) `
  query study($id: Int!) {
    study(id: $id) {
      ...StudyFragment
      level
      groomedPercentage
      score
    }
  }
  ${exports.StudyFragment}
`;
exports.StudyData = (0, utils_1.buildQuery)({
    query: exports.STUDY_QUERY,
});
function useStudyData(variables) {
    return (0, utils_1.useQueryFunction)(exports.STUDY_QUERY, {
        variables,
    });
}
exports.useStudyData = useStudyData;
function useStudiesData(options) {
    return (0, utils_1.useQueryFunction)(exports.STUDIES_QUERY, options);
}
exports.useStudiesData = useStudiesData;
exports.IMPORT_RAW_TRANSACTION = (0, client_1.gql) `
  mutation newStudyFromRawTransactionsFile($input: NewStudyWithTransactionInput!) {
    newStudyFromRawTransactionsFile(input: $input) {
      ...StudyFragment
      user {
        ...UserFragment
      }
    }
  }
  ${exports.StudyFragment}
  ${user_1.UserFragment}
`;
exports.importRawTransactionMutation = (0, utils_1.mutateFunction)({
    mutation: exports.IMPORT_RAW_TRANSACTION,
});
const CHECK_FILE_HEADERS = (0, client_1.gql) `
  query parseRawTransactionFileHeaders($s3Key: String!) {
    parseRawTransactionFileHeaders(s3Key: $s3Key)
  }
`;
exports.CheckFileHeaders = (0, utils_1.buildQuery)({
    query: CHECK_FILE_HEADERS,
});
const UPDATE_STUDY = (0, client_1.gql) `
  mutation updateStudy($id: Int!, $input: UpdateStudyInput!) {
    updateStudy(id: $id, input: $input) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.updateStudy = (0, utils_1.mutateFunction)({
    mutation: UPDATE_STUDY,
    mutateOpts: variables => ({ variables }),
});
const COMMIT_STUDY = (0, client_1.gql) `
  mutation commitStudy($id: Int!, $copyGrooming2: Boolean) {
    commitStudy(id: $id, copyGrooming2: $copyGrooming2) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.commitStudy = (0, utils_1.mutateFunction)({
    mutation: COMMIT_STUDY,
});
const COMMIT_REVIEWED_STUDY = (0, client_1.gql) `
  mutation finishStudy($id: Int!) {
    finishStudy(id: $id) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.finishStudyMutation = (0, utils_1.mutateFunction)({ mutation: COMMIT_REVIEWED_STUDY });
const REMATCH_TRANSACTION = (0, client_1.gql) `
  mutation rematchStudyTransactions($input: RematchTransactionsInput!) {
    rematchStudyTransactions(input: $input) {
      ...StudyTransactionFragment
    }
  }
  ${queuedTransaction_1.StudyTransactionFragment}
`;
exports.rematchStudyTransactionsMutation = (0, utils_1.mutateFunction)({
    mutation: REMATCH_TRANSACTION,
});
const MATCH_STUDY = (0, client_1.gql) `
  mutation matchStudy($id: Int!) {
    matchStudy(id: $id) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.matchStudyMutation = (0, utils_1.mutateFunction)({
    mutation: MATCH_STUDY,
});
const GEN_ARTIFACT_URL = (0, client_1.gql) `
  mutation genArtifactsUrl($id: Int!) {
    genArtifactsUrl(id: $id) {
      committedParts
      committedCases
      finalParts
      finalCases
      groomingResult
      originalFile
    }
  }
`;
exports.genStudyArtifactsUrlMutation = (0, utils_1.mutateFunction)({
    mutation: GEN_ARTIFACT_URL,
});
const SUBSCRIPTION_STUDIES_UPDATED = (0, client_1.gql) `
  subscription studiesUpdated {
    studiesUpdated {
      ...StudyFragment
      user {
        ...UserFragment
      }
      poCount
      gradedPoCount
    }
  }
  ${exports.StudyFragment}
  ${user_1.UserFragment}
`;
const useSubscriptionStudiesUpdated = () => {
    const result = (0, utils_1.useSubscriptionFunction)(SUBSCRIPTION_STUDIES_UPDATED);
    return result;
};
exports.useSubscriptionStudiesUpdated = useSubscriptionStudiesUpdated;
const SUBSCRIPTION_STUDY_UPDATED = (0, client_1.gql) `
  subscription studyUpdated($id: Int!) {
    studyUpdated(id: $id) {
      ...StudyFragment
    }
    poCount
    gradedPoCount
  }
  ${exports.StudyFragment}
`;
const useSubscriptionStudyUpdated = (id) => {
    const result = (0, utils_1.useSubscriptionFunction)(SUBSCRIPTION_STUDY_UPDATED, {
        variables: { id },
    });
    return result;
};
exports.useSubscriptionStudyUpdated = useSubscriptionStudyUpdated;
const PUSH_TO_CASESCOST = (0, client_1.gql) `
  mutation pushToCasesCodeStudy($input: PushToCaseCostInput!) {
    pushToCasesCodeStudy(input: $input) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.pushStudyToCasesCostMutation = (0, utils_1.mutateFunction)({
    mutation: PUSH_TO_CASESCOST,
});
const DELETE_STUDY = (0, client_1.gql) `
  mutation deleteStudy($id: Int!) {
    deleteStudy(id: $id) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.deleteStudyMutation = (0, utils_1.mutateFunction)({
    mutation: DELETE_STUDY,
});
const ARCHIVE_STUDY = (0, client_1.gql) `
  mutation archiveStudy($id: Int!) {
    archiveStudy(id: $id) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.archiveStudyMutation = (0, utils_1.mutateFunction)({
    mutation: ARCHIVE_STUDY,
});
const COMPLETE_STUDY = (0, client_1.gql) `
  mutation completeStudy($id: Int!) {
    completeStudy(id: $id) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.completeStudyMutation = (0, utils_1.mutateFunction)({
    mutation: COMPLETE_STUDY,
});
const RECOVER_STUDY = (0, client_1.gql) `
  mutation recoverStudy($id: Int!) {
    recoverStudy(id: $id) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.recoverStudyMutation = (0, utils_1.mutateFunction)({
    mutation: RECOVER_STUDY,
});
const CANCEL_STUDY = (0, client_1.gql) `
  mutation cancelStudy($id: Int!) {
    cancelStudy(id: $id) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.cancelStudyMutation = (0, utils_1.mutateFunction)({ mutation: CANCEL_STUDY });
const RETRY_STUDY = (0, client_1.gql) `
  mutation retryStudy($id: Int!) {
    retryStudy(id: $id) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.retryStudyMutation = (0, utils_1.mutateFunction)({ mutation: RETRY_STUDY });
const LINK_STUDY = (0, client_1.gql) `
  mutation linkStudy($id: Int!) {
    linkStudy(id: $id) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.linkStudyMutation = (0, utils_1.mutateFunction)({
    mutation: LINK_STUDY,
});
const COMMIT_STUDY_LINK = (0, client_1.gql) `
  mutation commitStudyLink($id: Int!) {
    commitStudyLink(id: $id) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.commitStudyLinkMutation = (0, utils_1.mutateFunction)({ mutation: COMMIT_STUDY_LINK });
const RETRY_LINKING_STUDY = (0, client_1.gql) `
  mutation retryLinkingStudy($id: Int!) {
    retryLinkingStudy(id: $id) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.retryLinkingStudyMutation = (0, utils_1.mutateFunction)({ mutation: RETRY_LINKING_STUDY });
const ROLLBACK_STUDY = (0, client_1.gql) `
  mutation rollbackStudy($id: Int!) {
    rollbackStudy(id: $id) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.rollbackStudyMutation = (0, utils_1.mutateFunction)({ mutation: ROLLBACK_STUDY });
const PUSH_LINK_TS_ID_TO_GROOMING = (0, client_1.gql) `
  mutation pushLinkTsIdToGrooming($id: Int!) {
    pushLinkTsIdToGrooming(id: $id) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.pushLinkTsIdToGroomingMutation = (0, utils_1.mutateFunction)({ mutation: PUSH_LINK_TS_ID_TO_GROOMING });
const CHECK_GROOMING_2_CONFLICTS = (0, client_1.gql) `
  mutation checkGrooming2Conflicts($id: Int!) {
    checkGrooming2Conflicts(id: $id) {
      ...StudyFragment
    }
  }

  ${exports.StudyFragment}
`;
exports.checkGrooming2ConflictsMutation = (0, utils_1.mutateFunction)({ mutation: CHECK_GROOMING_2_CONFLICTS });
exports.CREATE_CONFLICT_STUDY = (0, client_1.gql) `
  mutation newStudyFromConflictsItems(
    $userId: String
    $first: Int
    $skip: Int
    $importedFrom: Date
    $importedTo: Date
    $statusIn: [StudyStatusEnum!]
    $customerId: String
    $fileType: [StudyFileTypeEnum!]
  ) {
    newStudyFromConflictsItems(
      userId: $userId
      first: $first
      skip: $skip
      importedFrom: $importedFrom
      importedTo: $importedTo
      statusIn: $statusIn
      customerId: $customerId
      fileType: $fileType
    ) {
      ...StudyFragment
    }
  }
  ${exports.StudyFragment}
`;
exports.createNewConflictStudy = (0, utils_1.mutateFunction)({
    mutation: exports.CREATE_CONFLICT_STUDY,
});
